@import '../../../partials/global';


.brainflix {
    background-color:$background;
}

.video {
    margin-top: 20px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &__subtitle {
        margin-bottom: 20px;
    }
}