@import '../../partials/_global.scss';

.home {
    height: 100%;
    width: 100%;
    background-color: $background;
}


.hero {
    overflow:hidden;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin: 0 5% 0 5%;
    &__text {
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 25rem;
        padding-left: 10%;
        &__title {
            margin-bottom: 15px;
            display: flex;
            justify-content: flex-start;
            &__letter {
                display: block;
                color: transparent;
                font-weight: bold;
                font-size: 4rem;
                &--h {
                    animation: visibility 1s linear 0s 1 forwards;
                }
                &--e {
                    animation: visibility 1s linear 0.25s 1 forwards;
                }
                &--l {
                    animation: visibility 1s linear 0.5s 1 forwards;
                }
                &--l2 {
                    animation: visibility 1s linear 0.75s 1 forwards;
                }
                &--o {
                    animation: visibility 1s linear 1s 1 forwards;
                }

            }
        }
        &__sub-title {
            color: $blue;
            font-weight: bold;
            margin-bottom: 5px;
        }
    }
    &__img-container {
        width: 65%;
        background-color: $blue;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    &__girl-img {
        width: 25rem;
        z-index: 1;
        position: absolute;
    }
    &__girl-box {
        width: 25rem;
        height: 25rem;
        border: 3px solid $pink;
        position: relative;
        right: -190px;
        top: 25px;
        z-index: 2;

        &--back {
            border: 3px solid $brown;
            position:relative;
            top: -25px;
            right: 190px;
            z-index: 0;
        }

    }
    &__footer {
        &__scroll {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            animation: bob 1.5s linear 0s infinite forwards; 
            position: absolute;
            left: 50%;
            right: 50%;
            bottom: 5px;
            &-arrow {
                width: 20px;
            }
            &-text {
                font-size: 0.9rem;
                min-width: 200px;
                color: white;

            }
        }
    }

}










// eye
.eye {
    display: flex;
    min-width: 15px;
    max-width: 15px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid black;
    justify-content: center;
    align-items: center;
    background-color: white;
    position: relative;
    left: -56%;
    bottom: 8%;
    &--right {
        left: -49%;
    }
}


.eyeContainer {
    width: 10px;
    height: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

}

.eyeball {
    width: 7px;
    height: 7px;
    background-color: black;
    border-radius: 50%;
}