@import '../../partials/global';

.education {
    height: 100vh; 
    display: flex;
    background-color: $blue;
    padding: 8% 0% 5% 0%;
    overflow:hidden;
    &__container {
        background-color: #7da3c9;
        width: 55%;
        padding:0% 4%;
        border-radius: 15px;

    }
    &__container-bars {
        width: 45%;
        height: fit-content;
        background-color: $background;
        padding: 5%;
        border-radius: 25px;
        position: relative;
        left: 30px; 
    }
    &__school-icon {
        width: 8rem;
        margin-bottom: 35px;
    }
    &__schools {
        display: flex;
        justify-content: space-between;
        margin-top: 60px;

    }
}

.school-card {
    min-width: 200px;
    width: 30%;
    
    min-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $background;
    padding: 15px;
    border-radius: 15px;
    &__info {
        font-size: 0.9rem;
    }
}

.skill {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    &__name {
        margin-right: 20px;

    }
    &__bar {
        width: 70%;
        display: flex;
        justify-content: flex-start;
        position: relative;
        &__base {
            width: 100%;
            height: 50%;
            border-bottom: 4px solid #808080;
            opacity: 0.5;
        }
        &__pink {
            height: 50%;
            z-index: 2;
            position: absolute;
            border-bottom: 4px solid $pink;
            
            animation: bar-front-end 1s linear 1s 1 forwards;

            
            &--back-end {
                animation: bar-back-end 1s linear 1.5s 1 forwards;
            }
            &--react {
                animation: bar-react 1s linear 2s 1 forwards;
            }
            &--ruby {
                animation: bar-ruby 1s linear 2.5s 1 forwards;
            }
            &--design {
                animation: bar-design 1s linear 3s 1 forwards;
            }
        }
    }
}

.subtitle {
    color: white;
    font-weight: bold;
    margin-bottom: 10px;
}

.text {
    color: white;
    font-weight: 500;
}
