@import '../../partials/global';

.about {
    width: 100%;
    height: 100vh;
    padding:5%;
    display: flex;
    background-color: $background;
    justify-content: flex-start;
    &__container {
        width: 100%;
        border-radius: 25px;
        background-color: $background;
        display: flex;
        align-items: center;
        position: relative;

        &__profile {
            position: relative;
            display: flex;
            align-items: center;
            height: fit-content;
            margin-right: 100px;
        }
        &__content {
            width: 100%;
        }
    }
    &__buttons {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin-top: 30px;
        &__btn {
            // transform: scaleX(1);
            // animation: scale-0-1 1s linear 0s 1 forwards;
            padding: 10px;
            min-width: 150px;
            margin-right: 20px;
            color: $brown;
            background-color: transparent;
            border: 1px solid $brown;
            border-radius: 15px;
            outline: none;
            text-align: center;
            text-decoration: none;
            &:hover {
                animation: brown-btn-click 0.5s linear 0s 1 forwards;
            }
            &--pink {
                color: $pink;
                border: 1px solid $pink;

                &:hover {
                    animation: pink-btn-click 0.5s linear 0s 1 forwards;
                }
            }
        }

    }
}

.subtitle-about {
    margin-bottom: 10px;
    color: $brown;

}

.profile {
    width: 250px;
    position: absolute;
    z-index: 1;
    &__brown-box {
        border: 3px solid $brown;
        width: 250px;
        height: 250px;
        position: relative;
        top: -20px;
        right: -20px;
        opacity: 0.5;
    }
    &__blue-box {
        border: 3px solid $blue;
        width: 250px;
        height: 250px;
        right: 20px;
        top: 20px;
        position: absolute;
        opacity: 0.5;
        z-index: 2;

    }

}

.paragraphs {
    display: flex;
    &__paragraph {
        font-size: 1rem;
        // margin-right: 40px;
        margin-bottom: 20px;
        color: #808080;
        line-height: 28px;
    }
    &__container {
        margin-right: 80px;
    }
}