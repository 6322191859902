@import '_variables.scss';
@import '_fonts.scss';
@import '_animations.scss';

* {
    font-size: 1.1rem;
    font-family: 'Roboto';
    font-weight: normal;
    font-style: normal;
    margin: 0;
    padding: 0;
    text-decoration: none;
    box-sizing: border-box;
}

// subtitles
h2 {
    font-size: 1.5rem;

}