@import '../../partials/global';

.projects {
    // width: 100%;
    overflow: hidden;
    min-height: 50vh;
    padding: 5%;
    // padding-right: 0%;
    background-color: white;
    &__title {
        color: $brown;
        font-weight: bold;
    }
    &__list {
        width: 100%;
        justify-content: center;
        display: flex;
        margin-top: 30px;
        overflow: auto;
        white-space: nowrap;
    }
}