@import '../../../partials/global';

.project-page {
    background-color: $background;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.project-hero {
    padding: 10% 20% 10px 20%;
    width: 100%;
    &__title {
        font-size: 1.5rem;
        margin-bottom: 10px;
    }
}

.project-contents {
    padding: 50px 20% 100px 20%;
    width: 100%;
    display: flex;
    flex-direction: column;
    line-height: 25px;
    &__subtitle {
        margin-bottom: 10px;
    }
    &__para {
        margin-bottom: 30px;
    }
    

}

.github {
    display: flex;
    align-items: center;
    &__btn {
        text-align: center;
        padding: 5px;
        min-width: 150px;
        background-color: transparent;
        border: 1px solid $pink;
        margin-right: 10px;
    }
}

.designs {
    margin-top: 50px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__title {
        margin-bottom: 10px;
    }
    &__img {
        width: 70%;
        margin-bottom: 20px;
    }
}

.designs-mobile {
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    &__img {
        width: 25%;
        margin-right: 5px;
    }
}

.tools-sec {
    width: 100%;
    padding: 5% 10%;
    background-color: white;
    &__container {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

    }
    &__item {
        width: 100px;
    }
}