@import '../../partials/global';

footer {
    width: 100%;
    height: 30vh;
    background-color: $background;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-text {
    margin-bottom: 20px;
}

.contact-btn {
    outline: none;
    padding: 10px;
    border: 1px solid $brown;
    background-color: transparent;
    border-radius: 15px;
    &:hover {
        animation: brown-btn-click 1s linear 0s 1 forwards;
    }

}