@font-face {
    font-family: 'Ubuntu';
    src: url('../assets/fonts/Ubuntu/Ubuntu-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Comfortaa';
    src: url('../assets/fonts/Comfortaa/Comfortaa.ttf') format('truetype');
    font-weight: normal;
    font-style: 700;
}
@font-face {
    font-family: 'Arimo';
    src: url('../assets/fonts/Arimo/Arimo.ttf') format('truetype');
    font-weight: normal;
    font-style: 700;
}
@font-face {
    font-family: 'Noto';
    src: url('../assets/fonts/Noto/Noto-Bold.otf') format('opentype');
    font-weight: normal;
    font-style: 700;
}
@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}