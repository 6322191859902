@import '../../../partials/global';

.project {
    width: 360px;
    border-radius: 5px;
    background-color: $background;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin-right: 50px;
    padding-bottom: 20px;
    &__prev {
        width: 340px;
    }
}

.btns {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
    &__btn {
        width: 100%;
        outline: none;
        min-width: 130px;
        font-size: 1rem;
        padding: 5px;
        background-color: transparent;
        border: none;
        font-weight: 600;
        border-radius: 5px;
        text-decoration: none;
        text-align: center;
        &:hover {
            animation: brown-btn-click 0.5s linear 0s 1 forwards;
        }
        &--border {
            border: 1px solid $pink;
            &:hover {
                animation: pink-btn-click 0.5s linear 0s 1 forwards;
            }

        }

    }

}