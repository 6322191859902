@import './variables';

@keyframes move-text-color {
    0% {
        bottom: -3em;
        opacity: 1;
        color: #3E5C76;
      }
      
      50% {
        bottom: 0.2em;
        color: #669bbc;
      }
    
      100% {
        bottom: 0;
        opacity: 1;
        color: white;
      }
}

@keyframes bob {
  0% {transform: translateY(0.5px)}
  8% {transform: translateY(1px)}
  16% {transform: translateY(1.5px)}
  24% {transform: translateY(2px)}
  32% {transform: translateY(2.5px)}
  40% {transform: translateY(3px)}
  48% {transform: translateY(2.5px)}
  56% {transform: translateY(2px)}
  64% {transform: translateY(1.5px)}
  80% {transform: translateY(1px)}
  88% {transform: translateY(0.5px)}
  100% {transform: translateY(0x)}
}

@keyframes arrow-color-change {
    0% {color: #da5552}
    17% {color: #e76f51}
    24% {color: #fdb833}
    51% {color: #b5c99a}
    68% {color: #6096ba}
    85% {color: #274c77}
    100% {color: #7785ac}
}


@keyframes visibility {
  from {color: transparent;}
  to {color: #7E685A;}
}


// Buttons
@keyframes brown-btn-click {
  from {
    color: $brown;
    background-color: transparent;
  }
  to {
    color: white;
    background-color: $brown;
  }
}

@keyframes pink-btn-click {
  from {
    color: $pink;
    background-color: transparent;
  }
  to {
    color: white;
    background-color: $pink;
  }
}

@keyframes blue-btn-click {
  from {
    color: $brown;
  }
  to {
    color: $blue;
  }
}


// Skill Bars
@keyframes bar-front-end {
  from {
    width: 0%;
  }
  to {
    width: 90%;
  }
}

@keyframes bar-back-end {
  from {
    width: 0%;
  }
  to {
    width: 40%;
  }
}

@keyframes bar-react {
  from {
    width: 0%;
  }
  to {
    width: 80%;
  }
}

@keyframes bar-ruby {
  from {
    width: 0%;
  }
  to {
    width: 30%;
  }
}

@keyframes bar-design {
  from {
    width: 0%;
  }
  to {
    width: 60%;
  }
}

@keyframes about-appear {
  from {transform: translateX(-2000px)}
  to{ transform: translateX(2000px)}
}

@keyframes scale-0-1 {
  from {transform: scaleX(0)}
  to {transform: scaleX(1)}
}

@keyframes contacts-appear {
  from {transform: translateX(1000px)}
  to{ transform: translateX(0px)}
}

@keyframes contacts-disappear {
  from {transform: translateX(0px)}
  to{ transform: translateX(1000px)}
}