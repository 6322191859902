@import '../../partials/global';

.contacts {
    position: fixed;
    bottom: -25px;
    padding: 15px;
    right: -25px;
    z-index: 5;
    width: 30vw;
    height: 30vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    display: none;
    &--visible {
        display: flex;
        animation: contacts-appear 1s linear 0s forwards;
    }
    &--close {
        display: none;
        animation: contacts-disappear 1s linear 0s forwards;
    }
    &__icons {
        width: 25px;
        margin-right: 20px;
        &--location {
            width: 20px;
        }
    }
    &__items {
        width: fit-content;
    }
    &__item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        &__content {
            height: fit-content;
            font-size: 1rem;
        }
    }
    &__close {
        align-self: flex-start;
        border: none;
        background-color: transparent;
        outline: none;
    }
    &__title {
        font-size: 1.2rem;
        margin-bottom: 20px;
    }
}