@import '../../partials/global.scss';

.header {
    background-color: white;
    padding: 15px 20px 5px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    box-shadow: 0px 1px 10px rgba(232, 232, 232, 1);
    z-index: 3;
}

.nav {
    &__links {
        text-decoration: none;
        font-weight: bold;
        font-size: 1rem;
        color:$brown;
        margin-right: 30px;
        &:hover {
            animation: blue-btn-click 0.25s ease-in-out 0s 1 forwards;
        }
    }
    &__social {
        width: 25px;
        margin-left: 10px;
    }
}

.logo {
    width: 40px;
}