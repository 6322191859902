@import '../../../partials/global';

.designs-mobile {

    &--bobo {
        flex-direction: column;
    }
    &__pic {
        width: 25%;
        margin-right: 20px;
    }
    &__row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        &--bottom {
            justify-content: flex-start;
        }
    }
}

.notes-on-design {
    margin-left: 20px;
}

.github__btn--bobo {
    text-decoration: none;
    text-align: center;
}